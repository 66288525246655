
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent, sleep } from '@/utils/helpers';
import WellSignalsViewMixin from '@/components/tasqs/WellSignalsViewMixin';
import axiosClient from '@/lib/rest/axiosClient';
import gptModule from '@/store/modules/gptModule';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { i } from 'mathjs';

@Component({
  components: {
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    eventDetail: () => getComponent('tasqs/TasqEventSummaryPopup'),
    TasqLightningChart: () => getComponent('tasqs/TasqLightningChart'),
    TasqPlungerLightningChart: () =>
      getComponent('tasqs/TasqPlungerLightningChart'),
    TasqLightningProductionChart: () =>
      getComponent('tasqs/TasqLightningProductionChart'),
    ChartLegend: () => getComponent('tasqs/charts/ChartLegend'),
    ChartFailureView: () => getComponent('tasqs/charts/ChartFailureView'),
    ChartHeader: () => getComponent('tasqs/charts/ChartHeader'),
    ChartTimeRange: () => getComponent('tasqs/charts/ChartTimeRange'),
    DefermentLabeling: () => getComponent('tasqs/DefermentLabeling'),
  },
})
export default class WellSignalsSearchView extends mixins(
  WellSignalsViewMixin
) {
  headers = ['selected', 'Source Name', 'Tasq Name'];
  columns = [
    { key: 'selected', editable: false },
    { key: 'source_name', editable: false },
    { key: 'tasq_name', editable: true },
  ];
  tableData = [];

  dataLabel = '';

  showLegends = false;

  removeRangeSignalSelection1() {
    this.removeRangeSignalSelection();
    this.showRelabelPopup('signals');
  }

  selectDataForLabeling() {

    let startIndex = this.signalHighlightStartLocationIndex
    let endIndex = this.signalHighlightEndLocationIndex
    if(startIndex > endIndex){
      endIndex = this.signalHighlightStartLocationIndex
      startIndex = this.signalHighlightEndLocationIndex

    }
    const selectedSignals = tasqSignalsModule.selectedSignals;
    console.log(selectedSignals);

    console.log(startIndex);
    console.log(endIndex);

    const selectedSignalsTimeValues = tasqSignalsModule.currentSignals.find((s) =>
        s.time.length >= endIndex

    ).time;

    console.log(selectedSignalsTimeValues);
    const selectedSignalsValues = tasqSignalsModule.currentSignals.filter((s) =>
      selectedSignals.includes(s.name)

    );



    const values = selectedSignalsValues.map((signal) => {
      const vals = signal.dataset.slice(
       startIndex,
        endIndex + 1
      );

      return { [signal.name]: vals };
    });


    console.log(selectedSignalsTimeValues);
    const times = selectedSignalsTimeValues.slice(
      startIndex,
      endIndex + 1
    );

    console.log(times);






    const mergeDataArrays = (dataArray) =>
      dataArray.reduce((acc, current) => ({ ...acc, ...current }), {});

    if (values.length !== 0) {
      const mergedData = mergeDataArrays(values);
      tasqSignalsModule.setSearchSignalValues(mergedData);
    }

    gptModule.updateTimeseriesSearchStartEnd({
        start: times[0] ? times[0].split(':00Z')[0]+':00' : null,
        end:  times[0] ?  times[times.length - 1].split(':00Z')[0]+':00' : null,
      });
    this.$emit('search-signals-query')

    this.removeRangeSignalSelection();
  }


  findChangedRows(originalData, modifiedData) {
    console.log(originalData);
    console.log(modifiedData);
  const changedRows = modifiedData.filter((modifiedItem, index) => {
    const originalItem = originalData[index];
    // Ensure that the item exists in the original data and check for change
    return originalItem && modifiedItem.tasq_name !== originalItem.tasq_name;
  });
  return changedRows;
}



  orignalData: any  = [];

  async created() {
    if (!this.hideExtraDetails) {
      gptModule.updateTimeseriesSearchStartEnd({ start: null, end: null });
      const { data } = await axiosClient.get('/meta/get-well-signals', {
        params: {
          node_id: this.wellNameSearch,
          operator: this.operatorDetails['operatorName'],
        },
      });


      await sleep(1000)
      console.log(tasqSignalsModule.currentSignals.map((item) => item.name));
      this.tableData = data.map((item) => {
        const attr = item.attr;
        return {
          source_name: item.source_name || item.raw_data_name,
          _id: item._id,
          tasq_name: item.tasq_name,
          device: item.device,
          device_type: item.device_type,
          selected: tasqSignalsModule.selectedSignals.includes(item.tasq_name),
        };
      });
      this.orignalData = JSON.parse(JSON.stringify(this.tableData));
    }
  }

  get allSignalsNotSelected() {
    return this.tableData.some((item) => item.selected === false);
  }

  updateSelection(row = null) {
    if (row) {
      this.tableData.forEach((item) => {
        if (item.source_name === row.source_name) {
          item.selected = !item.selected;
        }
      });
    } else {
      const update = this.allSignalsNotSelected;
      console.log(this.allSignalsNotSelected);
      this.tableData.forEach((item) => {
        item.selected = update;
      });
    }
  }

  @Watch('dataLabel', { immediate: true })
  onDataLabelUpdate() {
    this.$emit('update-data-label', this.dataLabel);
  }

  get signalHighlightEndLocationIndex() {
    return tasqSignalsModule.signalHighlightEndLocationIndex;
  }

  get signalHighlightStartLocationIndex() {
    console.log(tasqSignalsModule.currentSignals);
    return tasqSignalsModule.signalHighlightStartLocationIndex;
  }

  convertDateFormat(dateStr) {
    // Split the date string on the comma to separate the date and time
    var parts = dateStr.split(', ');

    // Split the date part to get day, month, and year
    var dateParts = parts[0].split('/');

    // Construct a new date object using year, month (0-indexed), day, and the original time parts
    var timeParts = parts[1].split(':');
    var date = new Date(
      dateParts[2],
      dateParts[1] - 1,
      dateParts[0],
      timeParts[0],
      timeParts[1]
    );

    // Format the date and time in the desired ISO format
    var newDateStr =
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      'T' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2) +
      ':00';

    return newDateStr;
  }


  async updateSignalTasqName(){
    const changedRows = this.findChangedRows(this.orignalData,this.tableData );
    console.log(changedRows);
    if(changedRows.length > 0){
      const data = changedRows.map((row) => {
        return {
          _id: row._id,
          tasq_name: row.tasq_name,
          device: row.device,
          device_type: row.device_type,
          raw_data_name: row.source_name || row.raw_data_name,
        };
      });

      console.log(data);
      await axiosClient.post('/meta/update-signal-tasqname', {data: data});


    }
    this.showSelectedSignals = ! this.showSelectedSignals;

    this.loadSignalSearchData2(this.tableData.filter(k => k.selected && k.tasq_name).map(item => item.tasq_name));
  }

  // @Watch('signalHighlightEnd')
  // onStartEndUpdate() {
  //   if (this.signalHighlightEnd) {
  //     gptModule.updateTimeseriesSearchStartEnd({
  //       start: this.convertDateFormat(this.signalHighlightStart),
  //       end: this.convertDateFormat(this.signalHighlightEnd),
  //     });
  //   }
  // }
}
